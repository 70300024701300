<template>
  <div class="container" id="infoContainer">
    <!-- Title -->
    <div v-if="title != null" class="row" id="titleSection">
      <div class="col-md-12">
        <p id="title">{{ title }}</p>
      </div>
    </div>

    <!-- Votes -->

    <div class="row" id="voteSection">
      <div class="col" id="upvotes" @click="vote">
        <div class="voteWrapper">
          <img src="@/assets/icons/icon_thumbsup.svg" />
          {{ upvotes }}
        </div>
      </div>
      <div class="col" id="downvotes" @click="vote">
        <div class="voteWrapper">
          <img src="@/assets/icons/icon_thumbsdown.svg" />
          {{ downvotes }}
        </div>
      </div>
    </div>

    <!-- Statistics -->
    <div class="row" id="statisticSection">
      <div class="col">
        <p class="statistic">{{ t("views", views) }}</p>
      </div>

      <div class="col">
        <p class="statistic">{{ t("downloads", downloads) }}</p>
      </div>

      <div class="col">
        <p class="statistic">{{ t("shares", shares) }}</p>
      </div>
    </div>

    <!-- TAGS -->
    <!-- <div id="tagArea" v-if="tags.length">
      <span v-for="tag in tags.slice(0, 5)" :key="tag.id" class="tag" @click="showTag">{{tag.name}}</span>
    </div> -->
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { Tag } from '@/dto/Tag'
import { emitter } from '@/main'
import { EmittEvent } from '@/dto/enums/EmittEvent'
import { logTagClick, logVoteClick } from '@/services/EventLogger'

export default defineComponent({
  props: {
    title: {
      type: String
    },
    downloads: {
      type: Number,
      default: 0
    },
    shares: {
      type: Number,
      default: 0
    },
    views: {
      type: Number,
      default: 0
    },
    upvotes: {
      type: Number,
      default: 0
    },
    downvotes: {
      type: Number,
      default: 0
    },
    tags: {
      type: Array as PropType<Array<Tag>>,
      default: [] as PropType<Array<Tag>>
    }
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local'
    })

    return { t }
  },

  methods: {
    showTag() {
      logTagClick()
      this.showAppDownloadAlert()
    },
    vote() {
      logVoteClick()
      this.showAppDownloadAlert()
    },
    showAppDownloadAlert() {
      emitter.emit(EmittEvent.downloadAppAlert)
    }
  }
})
</script>

<style lang="sass" scoped>
#infoContainer
  padding: 0px

#titleSection
  padding-top: 6px
  padding-bottom: 6px

#title
  margin-bottom: 0px
  text-align: center

#voteSection
  margin-left: auto
  margin-right: auto
  width: 100%

.voteWrapper
  display: table
  margin-left: auto
  margin-right: auto
  padding: 11px

#upvotes
  cursor: pointer
  margin-right: 6px
  border-radius: 12px
  background-color: $color_surface

#downvotes
  cursor: pointer
  margin-left: 6px
  border-radius: 12px
  background-color: $color_surface

#statisticSection
  padding-top: 16px
  padding-bottom: 6px

.statistic
  padding: 0
  margin: 0
  text-align: center
  font-weight: 500
  font-size: 0.9rem
  color: $color_on_background_medium

#tagArea
  display: flex
  flex-direction: row
  flex-wrap: wrap
  padding-top: 6px
  padding-bottom: 6px

.tag
  background-color: $color_surface_overlay
  padding-left: 12px
  padding-right: 12px
  padding-top: 6px
  padding-bottom: 6px
  margin-right: 6px
  margin-top: 6px
  border-radius: 999px
  cursor: pointer
</style>

<i18n>
{
  "de": {
    "downloads": "0 Downloads | 1 Download | {n} Downloads",
    "shares": "{n} Mal Geteilt",
    "views": "0 Blicke | 1 Blick | {n} Blicke"
  },

  "en": {
    "downloads": "0 Downloads | 1 Download | {n} Downloads",
    "shares": "{n} Times Shared",
    "views": "0 Views | 1 View | {n} Views"
  }
}
</i18n>
